.email-sender {
    border: $border;
    border-radius: $border-radius;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 70%;
    min-width: 300px;
    min-height: 224px;
    position: relative;
    @include breakpoint(sm) {
        width: 95%;
        min-width: unset;
        max-width: 350px;
    }
    h2 {
        margin: 0;
        padding: 0;
    }
    span {
        color: $text-primary!important;
    }
    input, textarea {
        caret-color: auto;
        width: 100%;
        padding: 7px;
        border-radius: $border-radius;
        outline: none;
        border: 1px solid $background-input;
        background-color: $background-input;
        &:hover, &:focus {
            border: 1px solid $darken-primary;
        }
    }
    textarea {
        max-width: 100%;
        min-width: 100%;
        min-height: 100px;
        max-height: 300px;
    }
    .send-success {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgb(134, 198, 37);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            font-size: 1.5rem;
            font-weight: 500;
            text-align: center;
            color: #fff;
            text-shadow: 0 0 5px rgba(64, 101, 9, 0.6);
            &:last-child {
                font-size: 1.2rem;
                font-weight: 300;
            }
        }
        &.show-mode {
            animation: animshow 200ms forwards;
            @keyframes animshow {
                0% { height: 0px;}
                100% { height: 100%;}
            }
        }
        &.hide-mode {
            animation: animhide 200ms forwards;
            @keyframes animhide {
                0% { height: 100%; }
                100% { height: 0px; }
            }
        }
    }
  }