@import "../variables.scss";
@import "../preDefine/mixins.scss";

.app-home {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include breakpoint(sm) {
    height: auto;
  }
  .rect-home-page {
    width: 20%;
    height: 100vh;
    background-color: $secondary;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: $transition;
    @include breakpoint(xxl) {
      width: 25%;
    }
    @include breakpoint(xl) {
      width: 30%;
    }
    @include breakpoint(lg) {
      width: 35%;
    }
    @include breakpoint(md) {
      width: 40%;
    }
    @include breakpoint(sm) {
      display: none;
    }
  }
  .main-paper {
    width: 85%;
    height: 90%;
    max-width: 1400px;
    background-color: #fff;
    border-radius: $border-radius;
    z-index: 2;
    position: relative;
    box-shadow: $shadow-main-paper;
    overflow: hidden;
    margin-top: 20px;
    transition: $transition;
    @include breakpoint(md) {
      width: 90%;
      height: 90%;
    }
    @include breakpoint(sm) {
      width: 100%;
      height: 100%;
      margin-top: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.paper-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  .side {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    &.data {
      width: 55%;
      height: calc(100% - 80px);
      padding: 30px 0;
      margin-top: 80px;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
          display: none;
      }
    }
    &.flat {
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      padding-inline: 30px;
    }
    &.centered {
      justify-content: space-between;
    }
    &.image {
      background: rgb(79,193,255);
      background: radial-gradient(circle, rgba(79,193,255,0.5) 0%, rgba(144,183,204,0.5) 29%, rgba(198,175,161,0.5) 75%, rgba(154,131,117,0.5) 100%); 
      .image-box {
        img {
          height: unset !important;
          width: 100% !important;
        }
      }
      @include breakpoint(lg) {
        max-height: 100vh;
      }
    }
    &.video {
      position: relative !important;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @include breakpoint(lg) {
        min-height: 100%;
      }
    }
  }
  @include breakpoint(lg) {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    .side {
      width: 100% !important;
      height: auto;
      &.data {
        height: auto;
        margin-top: 30px;
        overflow: unset;
      }
      &.flat {
        margin-top: 80px;
        padding-inline: 0;
      }
    }
  }
}

.base {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @include breakpoint(lg) {
    padding-inline: 30px;
  }
  &.on-show-mode-all {
    transform: translate(-1000px, 0);
    animation: all_animshow 200ms forwards;
    @keyframes all_animshow {
        0% {
          opacity: 0.5;
          transform: translate(-1000px, 0);
        }
        100% {
          opacity: 1;
          transform: translate(0, 0);
        }
    }
  }
  &.on-hide-mode-all {
    display: none;
  }
  &.on-show-mode-one {
    height: 100%;
    transform: translate(1000px, 0);
    animation: one_animshow 200ms forwards;
    @keyframes one_animshow {
        0% {
          opacity: 0.5;
          transform: translate(1000px, 0);
        }
        100% {
          opacity: 1;
          transform: translate(0, 0);
        }
    }
  }
  &.on-hide-mode-one {
    display: none;
  }
}

.data-show {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  .image {
    width: 330px;
    height: 100%;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
  .infos {
    height: 100%;
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 2px solid rgb(228, 228, 228);
    border-radius: $border-radius;
    overflow-x: hidden;
    overflow-y: scroll;
    gap: 30px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    .more {
      width: 100%;
      display: flex;
      flex-direction: column;
      .images {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        @include breakpoint(lg) {
          justify-content: center;
        }
      }
    }
    @include breakpoint(lg) {
      overflow-x: unset;
    }
  }
  @include breakpoint(lg) {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    .image {
      width: 100%;
      height: auto;
    }
    .infos {
      border: none;
      width: 100%;
      height: auto;
      padding: 10px;
    }
  }
}