@import './font.scss';
@import '../variables.scss';
@import "../preDefine/mixins.scss";

* {
  box-sizing: border-box !important;
  scroll-behavior: smooth !important;
  caret-color: transparent;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

strong {
  color: $social !important;
}

body {
  margin: 0;
  font-family: AveriaLibre !important;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0 !important;
}

.menu-items {
  li {
    font-family: Chalkboard !important;
    font-size: 1.2rem;
    font-weight: 100;
  }
  .languages-menu {
    font-size: 1.2rem;
    font-weight: 100;
  }
}

.footer-lang {
  font-family: Chalkboard !important;
  font-size: 1rem;
  font-weight: 100;
}

.para-main {
  h2 {
    font-family: Audiowide !important;
    font-size: 2rem;
  }
}

.set-Audiowide-font {
  font-family: Audiowide !important;
}


.image-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 100%;
  }
}
.video-box {
  width: 100%;
}

.ibutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  background-color: $button-back;
  letter-spacing: 3px;
  border: none;
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: $border-radius;
  color: #fff;
  padding: 5px;
  &:hover {
    background-color: darken($button-back, 5%);
  }
  &:focus {
    background-color: darken($button-back, 15%);
  }
  &.fixedWidth {
    width: 100px;
    @include breakpoint(sm) {
      width: 100%;
    }
  }
  &.fullWidth {
    width: 100%;
    gap: 7px;
  }
}