.menu-items {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    z-index: 1000;
    // background-color: #fff;
    @include breakpoint(lg) {
        gap: 40px;
        width: 100%;
        background-color: $secondary-op3;
        padding: 10px 20px;
        // backdrop-filter: blur(2px) !important;
        box-shadow: 0 2px 10px #27272785;
    }
    @include breakpoint(sm) {
        position: fixed;
        padding: 3px 20px;
    }
    .image {
        width: 60px;
        height: 60px;
    }
    ul {
        flex-grow: 1;
        padding: 0 !important;
        margin: 0 !important;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        @include breakpoint(lg) {
            display: none;
        }
        li {
            transition: $transition;
            border-bottom: 2px solid white;
            cursor: pointer;
            position: relative;
            &.active {
                color: $text-primary;
                border-bottom: 2px solid $text-primary;
            }
            .menu-items-more-sign {
                position: absolute;
                top: 25px;
                width: 100%;
                text-align: center;
                @include breakpoint(lg) {
                    display: none;
                }
            }
        }
        .menu-items-more-mobile {
            display: none;
            @include breakpoint(lg) {
                display: block;
            }
        }
    }
    .menu-items-more {
        position: absolute;
        top: 85px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 15px 20px;
        border-radius: $border-radius;
        box-shadow: 0 0 10px #aeaeae84;
        background-color: $secondary-op8;
        color: #fff;
        .sub-menu-item {
            cursor: pointer;
            padding: 5px 10px !important;
            border-radius: $border-radius;
            transition: $transition;
            &:hover {
                background-color: $secondary-op8;
            }
            &.active {
                border: 1px solid #fff;
            }
        }
    }
    .menu-icon {
        color: $social;
        background-color: #fff;
        border-radius: $border-radius;
        height: 30px;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding-inline: 5px;
        svg {
            font-size: 24px;
        }
        @include breakpoint(maxlg) {
            display: none;
        }
    }
    .menu-back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        overflow: hidden;
        .back {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $glass-background;
            z-index: 1001;
            backdrop-filter: blur(2px);
        }
        .menu-list {
            z-index: 1002;
            background-color: #fff;
            width: 160px;
            height: 100%;
            padding: 40px 10px;
            box-shadow: -2px 0 15px #27272785;
            ul {
                padding: 0 !important;
                margin: 0 !important;
                list-style-type: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;
                li {
                    cursor: pointer;
                    &.active {
                        color: $text-primary;
                    }
                }
            }
            &.show-mode {
                transform: translate(1000px, 0);
                animation: menu_animshow 200ms forwards;
                @keyframes menu_animshow {
                    0% { transform: translate(1000px, 0); height: 0px; }
                    100% { height: 100%; transform: translate(0, 0); }
                }
            }
            &.hide-mode {
                transform: translate(0, 0);
                animation: menu_animhide 200ms forwards;
                @keyframes menu_animhide {
                    0% { transform: translate(0, 0); height: 100%;
                    }
                    100% { transform: translate(1000px, 0); height: 0px; display: none; }
                }
            }
        }
        @include breakpoint(maxlg) {
            display: none;
        }
    }
}