.footer-lang {
    width: 85%;
    max-width: 1400px;
    transition: $transition;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    gap: 20px;
    padding-inline: 10px;
    color: #6b6b6b;
    p {
        cursor: pointer;
        &:hover {
            color: #383838;
        }
        &.active {
            color: $text-primary;
        }
    }
    @include breakpoint(sm) {
        width: 100%;
        align-items: center;
        justify-content: center;
        background-color: rgba(71, 106, 121, 0.1);
    }
}

.languages-menu {
    margin-top: 30px;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #dedede;
    svg {
        height: 40px;
        width: 70%;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f4f4;
    }
    p {
        cursor: pointer;
        &:hover {
            color: #383838;
        }
        &.active {
            color: $text-primary;
        }
    }
}