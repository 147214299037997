.loading {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    background-color: $glass-background;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .circle {
        border: 12px solid lighten($secondary, 30%);
        border-top: 12px solid #fff;
        border-radius: 50%;
        width: 150px;
        height: 150px;
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}