@font-face {
    font-family: Chalkboard;
    src: url(../../Utils/Assets/fonts/Chalkboard-Regular.woff2) format('woff2');
}

@font-face {
    font-family: Audiowide;
    src: url(../../Utils/Assets/fonts/Audiowide-Regular.ttf) format("truetype");
}

@font-face {
    font-family: AveriaLibre;
    src: url(../../Utils/Assets/fonts/AveriaLibre-Regular.ttf) format("truetype");
}