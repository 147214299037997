.para-main {
    // text-align: center;
    width: 80%;
    line-height: 1.9rem;
    br {
        margin: 7px 0;
    }
    span {
        color: $text-primary !important;
        &.text-blue {
            color: $darken-primary !important;
            font-weight: 700;
        }
        &.text-qmark {
            color: $secondary !important;
        }
    }
    &.char-type {
        text-align: left;
        width: 100%;
        @include breakpoint(lg) {
            text-align: center;
        }
    }
}