.sub-character-image {
    width: 18%;
    min-width: 160px;
    height: 170px;
    position: relative;
    padding: 7px;
    box-shadow: $shadow-text-main;
    .image-id {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: hsla(0, 0%, 100%, 0.6);
        padding: 0 3px !important;
        border-radius: $border-radius;
        font-size: 0.8rem;
        font-weight: 500;
    }
    @include breakpoint(sm) {
        min-width: 140px;
        height: 155px;
    }
}

.characters-each {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .char-image {
        width: 230px;
        height: 320px;
        padding: 10px;
        background-color: #fff;
        box-shadow: $shadow-picture-main;
    }
    .char-info {
        background-color: #fff;
        box-shadow: $shadow-text-main;
        padding: 5px 10px;
    }
    &.effect {
        &:hover {
            &:nth-child(odd) {
                .char-image {
                    cursor: pointer;
                    rotate: 3deg;
                }
            }
            &:nth-child(even) {
                .char-image {
                    cursor: pointer;
                    rotate: -2deg;
                }
            }
        }
    }
    @include breakpoint(maxxxl) {
        width: 22%;
        .char-image {
            width: 100%;
        }
    }
    @include breakpoint(md) {
        max-width: 100%;
        .char-image {
            width: 100%;
        }
    }
    @include breakpoint(xxs) {
        .char-image {
            height: 200px;
        }
    }
    &.fixed {
        width: 100%;
        .char-image {
            width: 330px;
            height: 300px;
            @include breakpoint(lg) {
                width: 100%;
                max-width: 390px;
            }
        }
    }
}

.char-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    @include breakpoint(xl) {
        justify-content: center;
        gap: 30px;
    }
}