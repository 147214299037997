$primary: #4FC1FF;
$secondary: #9A8375;
$text-primary: #54A1C2;
$text-secondary: #887467;
$primary-op4: rgba(84, 161, 194, 0.4);
$primary-op5: rgba(84, 161, 194, 0.5);
$secondary-op8: rgba(144, 121, 103, 0.8);
$secondary-op3: rgba(144, 121, 103, 0.3);
$darken-primary: #476a79;
$background-input: #f3f7f9;
$button-back: $text-secondary;
$glass-background: rgba(9, 30, 72, 0.4);
$spc-background: linear-gradient(143deg, rgba(206,177,160,1) 0%, rgba(154,131,117,1) 100%);
$spc-background2: linear-gradient(143deg, rgb(225, 225, 225) 0%, rgb(161, 161, 161) 100%);
$social: $secondary;
$shadow-main-paper: 0 2px 15px rgba(0, 0, 0, 0.5);
$shadow-picture-main: 2px 5px 10px rgba(0, 0, 0, 0.5);
$shadow-text-main: 1px 3px 5px rgba(0, 0, 0, 0.3);
$border-radius: 4px;
$border: 3px solid $darken-primary;
$transition: all 0.4s ease;
$transition-short: all 0.2s ease;
$transition-long: all 0.5s ease-in-out;