@import '../variables.scss';

.popup-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2500;
    .popup-content {
        background: rgba(255, 255, 255, 0.6);
        border-radius: $border-radius;
        width: 90%;
        height: 90%;
        // max-width: 450px;
        // min-height: 220px;
        // max-height: 90%;
        box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.5);
        animation: sup 0.2s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: relative;
        @include breakpoint(lg) {
            height: auto;
        }
        @keyframes sup {
            from {
                transform: translate(0, 1000px);
            }
            to {
                transform: translate(0, 0);
            }
        }
        .dialog-head {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            background-color: rgba(220, 209, 202, 0.7);
            .title {
                font-weight: 500;
                font-size: 1.2rem;
            }
            .close-btn {
                padding: 3px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border-radius: $border-radius;
                background-color: #c4bab4;
                cursor: pointer;
                svg {
                    color: #9b928c !important;
                }
                &:hover {
                    background-color: #bfb3ab;
                    svg {
                        color: #978c84;
                    }
                }
            }
        }
        .dialog-body {
            width: 100%;
            height: 100%;
            padding: 40px 20px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            gap: 30px;
            &::-webkit-scrollbar {
                display: none;
            }
            .image {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 10px;
                img {
                    height: 100%;
                    border: 7px solid #fff;
                    box-shadow: $shadow-picture-main;
                    @include breakpoint(lg) {
                        height: unset;
                        width: 100%;
                    }
                }
            }
            .info {
                width: 100%;
                text-align: center;
                p {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}