.social-home {
    width: 100%;
    height: 2px;
    border: 1px solid $social;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    @include breakpoint(lg) {
        border: none;
    }
    @include breakpoint(sm) {
        margin-top: 30px;
        background-color: #fff;
    }
}
  
.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
    a {
        text-decoration: none;
        color: $social;
        padding: 3px;
        transition: $transition;
        img {
            width: 23px;
            border-radius: 50%;
            background-color: $social;
            transition: $transition;
            &:hover {
                background-color: $text-primary;
            }
        }
        svg {
            font-size: 1.4rem;
        }
        &:hover {
            color: $text-primary;
        }
    }
    &.inner-home {
        position: absolute;
        right: 50px;
        top: -32px;
        height: 40px !important;
        background-color: #fff;
        gap: 10px;
        padding-inline: 15px;
        a {
            padding: 5px;
            svg {
                font-size: 1.3rem;
            }
        }
        @include breakpoint(lg) {
            width: 100%;
            right: 0;
        }
    }
}