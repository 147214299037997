
.home-button {
    height: 165px;
    min-height: 165px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-inline: 20px;
    .spc-button {
        width: 220px;
        height: 100%;
        background: $spc-background;
        padding: 10px;
        border: none;
        transition: $transition-short;
        &>div {
            width: 100%;
            height: 100%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $secondary;
            img {
                width: 70px;
                transition: $transition-short;
            }
            p {
                font-size: 17px !important;
                font-weight: 900;
                transition: $transition-short;
            }
        }
        &:hover {
            padding: 12px;
            img {
                width: 66px;
            }
            p {
                font-size: 16px !important;
            }
        }
    }
}

.info-page {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    @include breakpoint(lg) {
        padding-inline: 20px;
    }
    .each-phase {
        width: 100%;
        background: $spc-background;
        padding: 10px;
        .inner-content {
            width: 100%;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
            padding-bottom: 15px;
            .inner-head {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding: 5px;
                gap: 15px;
                font-size: 1.2rem;
                color: $secondary;
                .index {
                    padding: 7px 12px !important;
                    font-size: 1.3rem;
                    font-weight: 500;
                    background-color: $text-primary;
                    border-radius: $border-radius;
                    color: #fff;
                }
                @include breakpoint(md) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            .inner-phase {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 15px;
                border-bottom: 1px solid $secondary;
                padding-bottom: 15px;
                &:last-child {
                    border: none;
                    padding-bottom: 0;
                }
                @include breakpoint(md) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 5px;
                }
                &.disable {
                    padding: 5px !important;
                    background-color: rgb(237, 235, 233);
                    border-radius: $border-radius;
                    flex-direction: row !important;
                    justify-content: space-between !important;
                    align-items: center !important;
                }
                .inner-phase-status {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    .inner-phase-icon {
                        width: 61px;
                        min-width: 61px;
                        height: 61px;
                        min-height: 61px;
                        border-radius: 50%;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        .circular-progress {
                            position: absolute;
                            z-index: 200;
                            top: 3px;
                        }
                        svg {
                            font-size: 3.2rem;
                            color: #fff;
                        }
                        &.status-1 {
                            background-color: green;
                        }
                        &.status-2 {
                            background-color: orange;
                            &.proccess {
                                svg {
                                    font-size: 2.5rem;
                                }
                            }
                        }
                        &.status-3 {
                            background-color: rosybrown;
                        }
                        &.status-4 {
                            background-color: gray;
                        }
                    }
                }
                .inner-phase-description {
                    flex-grow: 1;
                    text-align: left;
                }
                .inner-phase-step {
                    width: 40px;
                    min-width: 40px;
                    background-color: $text-primary;
                    text-align: center;
                    border-radius: $border-radius;
                    color: #fff;
                    font-size: 1.2rem;
                }
            }
        }
    }
    .each-wp {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-bottom: 10px;
        &:last-child {
            padding-bottom: 0;
            border: none;
        }
        @include breakpoint(md) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 5px;
        }
        .wp-icon {
            width: 100px;
            min-width: 100px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            img {
                width: 70px;
                transition: $transition-short;
                @include breakpoint(md) {
                    width: 50px;
                }
            }
            @include breakpoint(md) {
                width: 100%;
                justify-content: flex-start;
                align-items: center;
            }
        }
        .wp-info {
            flex-grow: 1;
            strong {
                font-size: 1.3rem;
            }
        }
        .text-blue {
            color: $darken-primary !important;
            font-weight: 700;
        }
        &:nth-child(odd) {
            img:hover {
                rotate: 7deg;
            }
        }
        &:nth-child(even) {
            img:hover {
                rotate: -7deg;
            }
        }
    }

    .future-roadmap {
        width: 100%;
        min-height: 80px;
        padding: 10px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        position: relative;
        .future-roadmap-back-image {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('./../../Utils/Assets/Images/Matrix.gif');
            background-repeat: repeat;
            background-size: contain;
            z-index: -1;
            opacity: 0.3;
        }
        .future-roadmap-back-color {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            background: linear-gradient(-25deg, #9A8375, #4FC1FF, #9A8375, #4FC1FF, #9A8375);
            background-size: 400% 400%;
            animation: backGradient 10s linear infinite;
            opacity: 0.4;
            @keyframes backGradient {
                0% {
                    background-position: 0% 100%;
                }
                50% {
                    background-position: 100% 0%;
                }
                100% {
                    background-position: 0% 100%;
                }
            }
        }
        p {
            text-align: center;
            background: linear-gradient(to right, transparent 20%, #fff 40%, #fff 60%, transparent 80%);
            background-size: 200% auto;
            color: $secondary;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 4s linear infinite;
            @keyframes shine {
                to {
                    background-position: -200% center;
                }
            }
        }
    }
}

.wp-image-back {
    position: absolute;
    bottom: 20px;
    right: 20px;
    opacity: 0.1;
    rotate: 10deg;
    width: 40%;
    transition: $transition;
    @include breakpoint(xl) {
        width: 45%;
    }
    @include breakpoint(lg) {
        width: 50%;
    }
    @include breakpoint(md) {
        width: 55%;
    }
    @include breakpoint(sm) {
        display: none;
    }
}